/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import GemodelData from 'views/DemoModels/GeoTour3DData';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

const StaithesPage = () => {
  return <WithLayout component={GemodelData} layout={Main} modelID={5} />;
};

export default StaithesPage;
